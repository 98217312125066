.header {
   width: 100%;
   height: 50px;
   box-shadow: 0 2px 2px rgb(28 5 77 / 5%), 0 12px 8px rgb(0 0 0 / 2%);
   background-color: white;
   position: fixed;
   display: flex;
   align-items: center;
   justify-content: space-between;
   z-index: 999;
   a {
      text-decoration: none;
      color: #aa46f9;
   }
   ul {
      width: 100%;
      height: 80%;
      list-style: none;
      padding: 0 20px;
      margin: 0;
      display: flex;
   }
}

.logo {
   font-size: 17px;
   font-weight: 500;
   height: 100%;
   display: flex;
   align-items: center;
   margin-right: 40px;
   img {
      padding-right: 4px;
      height: 29px;
   }
}

.link {
   display: flex;
   align-items: center;
   padding: 0 8px;
   font-size: 15px;

   &:hover {
      text-decoration: underline;
      text-decoration-color: #aa46f9;
   }

   &.selected {
      font-weight: bold;
      &:hover {
         text-decoration: none;
      }
   }
}

.userPreview {
   width: 26px;
   height: 26px;
   border-radius: 100px;
}

.username {
   font-size: 16px;
   margin: 0 20px 0 10px;
}

.logOut {
   margin-right: 20px !important;
}
