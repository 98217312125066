.header {
   margin: 20px 0 8px 0;
}

.label {
   margin: -3px 12px 0 0;
   font-size: 18px;
}

.list {
   background-color: #f6f6f6;
   border: 1px solid #bbbbbb;
   padding: 6px 2px;
   border-radius: 5px;
   margin-bottom: 40px;
}

.add {
   cursor: pointer;
   background-color: #aa46f9;
   width: 23px;
   height: 23px;
   font-size: 20px;
   border: none;
   border-radius: 5px;
   color: white;
   padding: 0;
}
