.premium {
   position: absolute;
   top: 10px;
   left: 10px;
   width: 32px;
   height: 32px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 100px;
   border: 1px solid white;
   background-color: white;
   filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))';
}
