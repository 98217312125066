.inputsContainer {
   border-left: 1px solid rgba(0, 0, 0, 0.12);
   padding: 16px;
   height: 512px;
   overflow-y: auto;

   label {
      font-size: 15px;
      font-weight: 600;
      min-width: 130px;
      text-align: right;
   }
   .input {
      display: flex;
      gap: 10px;
      margin: 6px 6px;
      font-size: 15px;
      height: 22px;
   }
}

.textarea {
   font-size: 16px;
   margin: 3px 0 6px 5px;
   resize: none;
   padding: 5px;
}

.btn {
   padding: 0 10px;
}

.sliderContainer {
   display: flex;
   gap: 16px;
   align-items: center;

   .sliderValue {
      font-weight: 500;
      width: 80px;
   }
}

.themeURL,
.sliderContainer,
.textarea,
.inputsContainer,
.input {
   flex: 1 1 0;
}

.badgeSelect {
   display: flex !important;
   gap: 0.5rem;
   align-items: center;
}

.colorPreview {
   border-radius: 4px;
   width: 1rem;
   height: 1rem;
   display: inline-flex;
}
