.root {
   position: relative;
   padding: 20px 15px;
}

.title {
   font-size: 32px;
}

.templatesByCategories {
   padding-bottom: 40px;
}

.btnGroup {
   display: flex;
   justify-content: right;
   gap: 10px;
}
