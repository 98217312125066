.templatesByCategories {
   padding-bottom: 40px;
}

.uncategorized {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 16px;
}
