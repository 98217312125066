.description {
   font-size: 12px;
   color: #6e7491;
   padding: 20px;
}

.buttons {
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 10px;
   padding: 20px;
}
