.previewContainer {
   width: 200px;
   height: 100%;
   padding: 0 16px;

   p {
      font-size: 16px;
      padding: 5px 0;
      margin: 8px 0 0 0;
   }
}

.preview {
   height: 120px;
   width: 100%;
   border-radius: 5px;
   background-color: #c7c7c7;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: 20px;
   img,
   video {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      z-index: 999;
      object-fit: contain;
   }
   div {
      position: absolute;
   }
}
