.category {
  padding-top: 40px;
  padding-bottom: 40px;
}

.aiGeneratable {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

.wrapper {
  height: fit-content;
}

.template {
  height: 154px;
}
