.title {
   font-size: 24px;
}

.close {
   width: 24px !important;
   height: 24px !important;
   cursor: pointer;
   path {
      color: #686868;
   }
}
