.form {
   padding: 16px;
   display: flex;
   flex-direction: column;
   gap: 16px;

   label {
      font-size: 16px;
      font-weight: 600;
      width: 200px;
      text-align: right;
   }

   .input {
      flex: 1;
      font-size: 16px;
   }
}
