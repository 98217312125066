.root {
   padding: 40px;
}

.title {
   margin: 0 0 10px 0;
   font-size: 32px;
   text-align: center;
}

.text {
   text-align: center;
   font-size: 17px;
}
// confirmation
.btn {
   background-color: #3442b1 !important;
   color: white !important;
   padding: 12px 16px !important;
}
