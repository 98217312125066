.templateItem {
   background-color: #e9effa;
   border: 2px solid #cbd4e6;
   color: #52556f;
   border-radius: 4px;
   overflow: hidden;
   width: 180px;
   position: relative;
   padding: 0;
   margin: 0;
   display: flex;
   flex-direction: column;
   align-items: center;
   cursor: pointer;

   .icon {
      position: absolute;
      inset: 0;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      margin: auto;
      color: white;
      border-radius: 4px;
      display: none;
      background-color: rgba($color: #000000, $alpha: 0.5);
   }

   &:hover {
      .icon {
         display: flex;
      }
   }

   &.selected {
      border: 2px solid #aa46f9;
      background-color: #dedcff;
   }
}

.name {
   font-size: 14px;
   padding: 8px 12px;
   text-align: center;
   font-weight: 600;
}

.img {
   width: 100%;
   height: 100px;
   object-fit: cover;
}

.hidden {
   position: absolute;
   background-color: red;
   color: white;
   border-radius: 100px;
   padding: 4px 15px;
   top: 14px;
   left: 50%;
   transform: translateX(-50%);
   font-size: 16px;
   font-weight: bold;
}

.update {
   position: absolute;
   border-radius: 100px;
   padding: 5px;
   background-color: #aa46f9;
   color: white;
   top: 50px;
   left: 12px;
   font-size: 11px;
   font-weight: bold;
}
