.title {
   font-size: 32px;
}

.header {
   margin: 20px 0 10px 0;
   display: flex;
   flex-direction: row;
}

.label {
   margin: -3px 12px 0 0;
   font-size: 18px;
}

.add {
   cursor: pointer;
   background-color: #aa46f9;
   width: 23px;
   height: 23px;
   font-size: 20px;
   border: none;
   border-radius: 5px;
   color: white;
   padding: 0;
}

.list {
   background-color: #f6f6f6;
   border: 1px solid #bbbbbb;
   min-height: 140px;
   padding: 12px;
   gap: 12px;
   align-items: stretch;
   border-radius: 4px;
   display: flex;
   overflow-x: auto;
}
