.appContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    .contentContainer {
        height: 100%;
        margin-top: 50px;
        padding: 0 20px;
    }
}
