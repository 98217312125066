.templatesContainer {
   position: relative;
   padding: 20px 15px;
}

.header {
   display: flex;
   flex-direction: row;
   align-items: center;
}
.title {
   font-size: 32px;
}

.search {
   margin-left: auto;
   width: 250px;
   height: 40px;
   padding: 0 10px;
}
